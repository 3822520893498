import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';
import { EventsImportComponent } from './events-import/events-import.component';
import { EventsItemFormComponent } from './events-item-form/events-item-form.component';
import { EventsItemComponent } from './events-item/events-item.component';
import { EventsListComponent } from './events-list/events-list.component';
import { EventsRoutingModule } from './events-routing.module';

@NgModule({
  declarations: [EventsItemComponent, EventsItemFormComponent, EventsListComponent, EventsImportComponent],
  imports: [CommonModule, EventsRoutingModule, SharedModule],
  exports: [EventsListComponent],
})
export class EventsModule {}
