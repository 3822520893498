<app-base-page
  [breadCrumb]="breadCrumb"
  [pageActions]="[]"
  [pageActionsCommandData]="null"
  [showTreeSwitch]="true"
  [treeIcon]="'pi pi-sitemap'"
>
  <ng-container page-header>
    <!-- <h4></h4> -->
  </ng-container>
  <app-table
    [cols]="cols"
    [tableActions]="tableActions"
    [actionCol]="actionCol"
    [inputData]="tableData"
    (filtersChanged)="onFilterChange($event)"
    [pageInfo]="tableEvent.pageInfo"
    (onExportData)="loadExportData($event)"
    (onFixRelationAction)="onFixRelations($event)"
    (onExportPackage)="loadExportPackage($event)"
    (onOpenEditDialog)="onOpenEditDialog($event)"
    (onSwapPageMode)="onSwapPageMode($event)"
    [pageActions]="pageActions"
    [pageActionsCommandData]="pageActionsCommandData"
    [sessionStorageKey]="sessionStorageKey"
    [isLoading]="isLoading"
    [settingsUrl]="settingsUrl"
    [moduleKeyword]="moduleKeyword"
    [showBulkOperationButton]="true"
    [bulkOperationUrl]="bulkOperationUrl"
    [showSwapPageMode]="true"
  ></app-table>
</app-base-page>
<p-dialog header="Select Entity Type" [(visible)]="visible" [style]="{ width: '50vw' }">
  @if (visible) {
    <app-target-code-selector
      [inlineMode]="true"
      [label]="'Entity Type'"
      [placeholder]="'Search Entity Types'"
      [control]="entityTypeFormControl"
      [viewMode]="'create'"
      [multi]="false"
      [optionLabel]="'name'"
      [optionValue]="undefined"
      [dataKey]="'code'"
      [appendTo]="'body'"
      [extraFilters]="this.categoryList ? [{ property: 'category', operation: 'IN', value: this.categoryList }] : []"
      (onChanges)="onEntityTypeChange($event)"
      [targetTypes]="['ENTITY_TYPE']"
    >
    </app-target-code-selector>
  }
  <ng-template pTemplate="footer">
    <p-button (click)="importItems()" label="Proceed" styleClass="p-button"></p-button>
  </ng-template>
</p-dialog>
