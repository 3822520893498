import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  EventFieldDto,
  ImportBasePage,
  ImporterColumnDefinitions,
  ModuleKeywords,
  ModuleRoutePrefix,
  toKebabCase,
} from '@shared/classes';
import { EntityTypesDataService } from '../../services/data/entity-types-data.service';
import { EventsDataService } from '../../services/data/events-data.service';
import { ResponsibilitiesDataService } from '../../services/data/responsibilities-data.service';

@Component({
  selector: 'app-events-import',
  templateUrl: './events-import.component.html',
  styleUrls: ['./events-import.component.scss'],
})
export class EventsImportComponent extends ImportBasePage implements OnInit {
  typeId: any;
  constructor(
    public requestService: EventsDataService,
    private responsibilityService: ResponsibilitiesDataService,
    private typeRequestService: EntityTypesDataService,
    private route: ActivatedRoute
  ) {
    super({ moduleKeyword: ModuleKeywords.Event, routePrefix: ModuleRoutePrefix.Entity }, requestService);
    this.subs.sink = this.route.params.subscribe({
      next: (params) => {
        this.typeId = params['typeId'];
        this.SetPageValues({
          breadCrumb: {
            items: [
              {
                label: 'Entity',
                routerLink: [`${this.routePrefix ? this.routePrefix : toKebabCase(this.moduleKeyword)}`],
              },
              {
                label: 'Events',
                routerLink: [`/${this.routePrefix}${toKebabCase(this.moduleKeyword)}/list`],
              },
              { label: 'Import Data', routerLink: [] },
            ],
          },
          colDefinitions: ImporterColumnDefinitions[this.moduleKeyword],
          extraBodyObjects: { type: this.typeId },
        });
        this.initAsyncDropDownFields();
      },
    });
  }

  ngOnInit(): void {}
  initAsyncDropDownFields() {
    // this.subs.sink = this.typeRequestService.search<EntityTypeFieldDto>(
    //     { all: true },
    //     { projectionFields: ['code'], filters: [{ "property": "category", "operation": "EQUAL", "value": EntityTypeFieldDto.CategoryEnum.Event }] },
    //     { showLoading: false, showMsg: false }
    // ).subscribe({
    //     next: (res:any) => {
    //         let list = res.map(x => x.code).join(",");
    //         this.colDefinitions[1].field.validators = [{
    //             key: 'dropdown',
    //             value: {
    //                 "type": "dropdown",
    //                 "type2": null,
    //                 "value1": list,
    //                 "value2": "",
    //                 "checked": false,
    //                 "remote": false,
    //                 "prohibitInput": false,
    //                 "hintShow": false,
    //                 "hintText": ""
    //             }
    //         }, ,];
    //         this.colDefinitions = [...this.colDefinitions];
    //     }
    // });
    // this.subs.sink = this.responsibilityService.getResponsibilityList(['code']).subscribe((res:any)=>{
    //     let list = res.map(x => x.code).join(",");
    //     this.colDefinitions[1].field.validators = [{
    //         key: 'dropdown',
    //         value: {
    //             "type": "dropdown",
    //             "type2": null,
    //             "value1": list,
    //             "value2": "",
    //             "checked": false,
    //             "remote": false,
    //             "prohibitInput": false,
    //             "hintShow": false,
    //             "hintText": ""
    //         }
    //     }, ,];
    //     this.colDefinitions = [...this.colDefinitions];
    // })
    // this.subs.sink = this.requestService.aggregate({
    //     aggregationFields: [
    //         {
    //             operation: "COUNT",
    //             fieldName: "code",
    //         }
    //     ],
    //     groupByFields: ["code"],
    // },
    //     { showLoading: false, showMsg: false }
    // ).subscribe((res: any) => {
    //     res;
    // })
    this.subs.sink = this.requestService
      .search<EventFieldDto>(
        { all: true },
        { projectionFields: ['code'], filters: [] },
        { showLoading: false, showMsg: false }
      )
      .subscribe((res: any) => {
        let list = res.map((x) => x.code).join(',');
        this.colDefinitions[2].field.validators = [
          {
            key: 'dropdown',
            value: {
              type: 'dropdown',
              type2: null,
              value1: list,
              value2: '',
              checked: false,
              remote: false,
              prohibitInput: false,
              hintShow: false,
              hintText: '',
            },
          },
          ,
        ];
        this.colDefinitions = [...this.colDefinitions];
      });
    this.subs.sink = this.typeRequestService.getByIdOrCode(this.typeId).subscribe((res) => {
      res?.data?.fields.forEach((item) => {
        this.colDefinitions.push({
          id: this.colDefinitions.length + 4,
          type: 'text',
          field: {
            name: item.name, // 'Name',
            key: item.key, //'name',
            required:
              (item?.options?.GENERAL_REQUIRED as any) === true || (item?.options?.GENERAL_REQUIRED as any) === 'true'
                ? true
                : false,
            validators: null,
          },
          fieldGroupKey: 'dynamics',
          customValueObject: {
            valueKey: 'value',
            customObject: {
              field: item.code,
              value: null,
            },
          },
        });
      });
      this.colDefinitions = [...this.colDefinitions];
    });
  }
}
